import React, {Component} from 'react';
import {Col, Row} from 'reactstrap';
import {Button, Typography} from '@mui/material';
import {connect} from 'react-redux';
import {saveApplicationsFilter} from '../actions';
import {logIn} from '../../../AWS';
import Featured from '../Featured';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleUser} from '@fortawesome/pro-light-svg-icons';
import SearchAppsAutocomplete from '../../common/SearchAppsAutocomplete';

class Head extends Component {
    state = {
        searchText: '',
        imgPath: '/img/logos/'
    };

    onTextChange = (e, text) => {
        this.setState({searchText: text});
    };

    onAppChange = (e, app) => {
        this.props.saveApplicationsFilter(app ?? '');
    };

    getGreeting = () => {
        const hours = new Date().getHours();
        let greeting = '';
        switch (true) {
            case hours > 5 && hours < 12:
                greeting = 'Good morning!';
                break;
            case hours >= 12 && hours < 18:
                greeting = 'Good afternoon!';
                break;
            case hours >= 18:
                greeting = 'Good evening!';
                break;

            // midnight to 4AM is an inbetween time
            default:
                greeting = 'Hello!';
        }

        return greeting;
    };

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <Typography
                            component="h1"
                            className="myOdu__stylizedText"
                            sx={{color: 'secondary.contrastText', pl: 2, pt: 1}}
                        >
                            <span>My</span>
                            <span>ODU</span>
                        </Typography>
                    </Col>
                    <Col style={{textAlign: 'right'}}>
                        <Button
                            variant="outlined"
                            sx={{m: 1}}
                            className="myOdu__button landingSignIn"
                            onClick={logIn}
                        >
                            <FontAwesomeIcon icon={faCircleUser} size="lg" />
                            <Typography sx={{ml: 1}} variant="smaller">
                                Sign In
                            </Typography>
                        </Button>
                    </Col>
                </Row>

                <div
                    className="myOdu__decorativeLine landing"
                    style={{width: '100%'}}
                >
                    &nbsp;
                </div>

                <Row className="myOdu__crownBkg pb-3 pt-5 justify-content-center">
                    <Col xl={11}>
                        <Row className="px-2">
                            <Col>
                                <Typography component="h2" className="greeting">
                                    {' '}
                                    {this.getGreeting()} How can we help?
                                </Typography>
                            </Col>
                        </Row>
                        <Row className="pb-3 px-2">
                            <Col>
                                <SearchAppsAutocomplete />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Featured />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="myOdu__trapezoid">
                            <span className="sr-only visally-hidden"></span>
                        </div>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    saveApplicationsFilter: text => dispatch(saveApplicationsFilter(text))
});

export default connect(mapDispatchToProps)(Head);
