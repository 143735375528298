import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    Backdrop,
    Stack,
    Fade,
    Box,
    Typography,
    TextField,
    Button,
    FormControl,
    InputLabel
} from '@mui/material';
import {saveImpersonation, toggleImpersonation} from './actions';
import {getData} from '../../DataAccessLayer';
import {impersonation} from '../../DataAccessLayer/services';
import PortalModal from '../common/PortalModal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUserSecret} from '@fortawesome/free-solid-svg-icons';
import {REDIRECT_TIMEOUT} from '../common/constants';

class Impersonation extends Component {
    state = {
        text: '',
        errorMessage: 'MIDAS not found'
    };

    onChange = e => {
        this.setState({text: e.target.value});
    };

    impersonate = () => {
        const midas = this.state.text;
        getData(impersonation + '/' + midas)
            .then(data => {
                data = {
                    ...data,
                    isImpersonating: true,
                    updatedAt: Date.now()
                };
                console.log(data);
                this.props.saveImpersonation(data);
                this.props.toggleImpersonation();
                this.setState({text: '', isError: false});
                // Hacky fix for impersionation failed to clear data for chrome.
                // We need to delay before reload
                setTimeout(() => (window.location = '/'), REDIRECT_TIMEOUT);
            })
            .catch(err => {
                this.setState({isError: true});
            });
    };

    render() {
        return (
            <React.Fragment>
                <PortalModal
                    maxWidth="xs"
                    onPrimaryClick={this.impersonate}
                    onClose={this.props.toggleImpersonation}
                    isOpen={this.props.isImpersonation}
                    noHeader
                    id="impersonateModal"
                    primaryButtonTitle="Impersonate User"
                    isPrimaryButtonDisabled={!this.state.text}
                    isCloseable={true}
                >
                    <Stack direction="row" sx={{mb: 2}}>
                        <FontAwesomeIcon
                            icon={faUserSecret}
                            fixedWidth
                            size="lg"
                        />
                        <Typography id="transition-modal-title" component="h2">
                            Impersonate User
                        </Typography>
                    </Stack>

                    <Stack direction="row">
                        <Typography
                            id="transition-modal-title"
                            component="label"
                            className="myOdu__label"
                            sx={{mt: 1, mr: 1}}
                        >
                            MIDAS
                        </Typography>
                        <TextField
                            id="outlined-basic"
                            error={this.state.isError}
                            size="small"
                            label="MIDAS"
                            variant="outlined"
                            value={this.state.text}
                            onChange={this.onChange}
                            fullWidth
                            inputProps={{maxLength: 16}}
                            helperText={
                                this.state.isError
                                    ? this.state.errorMessage
                                    : ''
                            }
                            className="myOdu__hideFloatLabel"
                        />
                    </Stack>
                </PortalModal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        isImpersonation: state.impersonationReducer.isImpersonation
    };
};

const mapDispatchToProps = dispatch => ({
    toggleImpersonation: () => dispatch(toggleImpersonation()),
    saveImpersonation: impersonation =>
        dispatch(saveImpersonation(impersonation))
});

export default connect(mapStateToProps, mapDispatchToProps)(Impersonation);
