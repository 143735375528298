export const CAMPUS = 'campus';
export const HOUSING = 'isHousing';
export const ICON_PATH = '/img/logos/';
export const MOVE_WIDGET_UP = 'UP';
export const MOVE_WIDGET_DOWN = 'DOWN';
export const CHECKLIST_YES_INDICATOR = 'Y';
export const SYSTEM_STATUS_IDENTIFIED = 'identified';
export const SYSTEM_STATUS_FIXED = 'fixed';
export const GOOGLE_ANALYTICS_KEY = 'GTM-5HPKDTRG';
export const mealsThreshold = '10';
export const flexPointsThreshold = '50';
export const MAIN_BRANCH = 'main';
export const CURRENT_TERM = 'CURRENT_TERM';
export const cardCenterMonarch = 'https://cardcenter.odu.edu/monarch';
export const studentAccount =
    'https://secure.touchnet.com/C21192_tsa/web/caslogin.jsp';
export const TIME_ZONE = 'America/New_York';
export const REDIRECT_TIMEOUT = 1;
export const WAITLIST_TUTORIAL =
    'https://www.odu.edu/sites/default/files/documents/how-to-use-waitlisting.pdf';
export const LEO_REGISTRATION =
    'https://ssb-prod.ec.odu.edu/PROD/twbkwbis.P_GenMenu?name=bmenu.P_RegMnu';
// Probabilty to fetch and show random infotip for user. Example 0.2 is 20%
export const INFOTIPS_DISPLAY_PROBABILTY = 0.2;
// Number of infotips should be displayed
export const INFOTIPS_DISPLAY_NUMBER = 1;
//The wait time to trigger update preferences api call on widget's expansion status change
export const WIDGETS_THROTTLE_DELAY = 3000;
//Default display options added to user preferences
export const DEFAULT_DISPLAY_OPTIONS = {
    isSidebar: true
};
export const MOVE_CONTACT_UP = 'UP';
export const MOVE_CONTACT_DOWN = 'DOWN';
export const ODU_DIRECTORY = 'http://odu.edu/directory';

//Course Types is used for Tutoring
export const TUTORING_COURSE_TYPE = {
    ALL: 'All',
    SPECIFIC: 'Specific'
};
