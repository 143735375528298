export const adminGroupName = 'DL MyODU Portal Admins';
export const powerUserGroupName = 'DL MyODU Portal Power Users';
export const samUserSuperGroupName = 'DL MyODU Portal SAM Super Users';
export const samUserPowerGroupName = 'DL MyODU Portal SAM Power Users';
export const samUserGroupName = 'DL MyODU Portal SAM Users';
export const advisorRoleName = 'ADVISOR';
export const impersonationGroupName = 'DL MyODU Portal Impersonators';
export const facultyRoleName = 'FACULTY';
export const staffRoleName = 'STAFF';
export const studentRoleName = 'STUDENT';
export const medicalStudentRoleName = 'SCHOOL_OF_MEDICINE_STUDENT';
export const healthProfessionsStudentRoleName = 'SCHOOL_OF_HEALTH_PROF_STUDENT';
export const medicalResidentRoleName = 'MEDICAL_RESIDENT';
//EVCM = people at the Fort Norfolk Campus
export const evmcRoleName = 'EVMC';
export const evmcStaffRoleName = 'EVMC_STAFF';
export const evmcFacultyRoleName = 'EVMC_FACULTY';
//ECHSC = people involved in the school, could be Fort Norfolk campus, could be Norfolk Main campus
export const evhscRoleName = 'EVHSC';
export const evhscFacultyRoleName = 'EVHSC_FACULTY';
export const evhscStaffRoleName = 'EVHSC_STAFF';
//This is the default role which will be used if a user does not have any roles
export const DEFAULT_ROLE = {
    STUDENT: true
};
