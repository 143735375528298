import { Divider, Stack, Typography } from '@mui/material';
import {toTitleCase} from '../../common/utils';
import {getCampusFromSiteCode} from '../utils';
import { Row } from 'reactstrap';
import { render } from 'react-dom';

//θ(1) Returns all the columns of the datagrid
export const getColumns = () => {
    return [
        {
            field: 'LASTNAME',
            headerName: 'Last Name',
            width: 100,
            valueGetter: (value, row) => {
                const lastName = row.NAME.split(',')[0];
                return toTitleCase(lastName);
            }
        },
        {
            field: 'FIRSTNAME',
            headerName: 'First and Middle Name',
            width: 200,
            valueGetter: (value, row) => {
                const firstName = row.NAME.split(',')[1];
                return toTitleCase(firstName);
            }
        },
        {
            //TODO: Needs to be updated
            field: 'MIDAS',
            headerName: 'MIDAS'
        },
        {
            field: 'SPRIDEN_ID',
            headerName: 'Student ID'
        },
        {
            field: 'EMAIL',
            headerName: 'Email'
        },
        {
            field: 'SGBSTDN_LEVL_CODE',
            headerName: 'Level',
        },
        {
            field: 'SGBSTDN_STYP_CODE',
            headerName: 'Student Type',
        },
        {
            field: 'CLAS_CODE',
            headerName: 'Class',
        },
        {
            field: 'SGBSTDN_DEGC_CODE_1',
            headerName: 'Degree',
        },
        {
            field: 'SGBSTDN_SITE_CODE',
            headerName: 'Site',
        },
        {
            field: 'CAMPUS',
            headerName: 'Campus',
            valueGetter: (value, row) =>
                getCampusFromSiteCode(row.SGBSTDN_SITE_CODE),
        },
        {
            field: 'DEPARTMENT_DETAIL',
            headerName: 'Department',
            renderCell: (params) => {
                const departments = params.row.DEPARTMENTS;
                return <Stack direction={'column'} gap={1}>
                    {
                        departments.map(department => {
                            return <Typography variant='p' sx={{whiteSpace: 'nowrap'}} noWrap>{department}</Typography>
                        })
                    }
                </Stack>
            },
            valueGetter: (value, row) => {
                const departments = row.DEPARTMENTS;
                return departments.join(' ');
            }
        },
        {
            field: 'TYPE',
            headerName: 'Type',
            renderCell: (params) => {
                const types = params.row.TYPES;
                return <Stack direction={'column'} gap={1}>
                    {
                        types.map(type => {
                            return <Typography variant='p' sx={{whiteSpace: 'nowrap'}} noWrap>{type}</Typography>
                        })
                    }
                </Stack>
            },
            valueGetter: (value, row) => {
                const types = row.TYPES;
                return types.join(' ');
            }
        },
        {
            field: 'FIELDOFSTUDY',
            headerName: 'Field of Study',
            width: 200,
            renderCell: (params) => {
                const fields = params.row.FIELD_OF_STUDIES;
                return <Stack direction={'column'} gap={1}>
                    {
                        fields.map(field => {
                            return <Typography variant='p' sx={{whiteSpace: 'nowrap'}} noWrap>{field}</Typography>
                        })
                    }
                </Stack>
            },
            valueGetter: (value, row) => {
                const fields = row.FIELD_OF_STUDIES;
                return fields.join(' ');
            }
        },
        {
            field: 'SGRADVR_PRIM_IND',
            headerName: 'Primary Advisor',
        },
        {
            field: 'ENROLLED',
            headerName: 'Enrolled',
        }
    ];
};
