import React, {Component} from 'react';
import { DefaultDepartment, PageDetails } from '../config';
import { Box, Container, Typography } from '@mui/material';
import PageHeader from '../PageHeader';
import SAMFilter from '../SAMFilter';
import { connect } from 'react-redux';
import { sam } from '../../../DataAccessLayer/services';
import { getData, postData } from '../../../DataAccessLayer';
import StudentDetails from '../StudentDetails';
import { getColumns } from './utils';
import AssignAdvisorDialog from '../AssignAdvisorDialog';
import { toast } from 'react-toastify';
import { createSelectedStudentObject, formatStudents } from '../utils';

class UnassignedStudents extends Component {

    state = {
        id: 'unassignedStudents',
        title: '',
        activeDepartment: {},
        students: [],
        isLoading: false,
        isSearchResult: false,
        columns: getColumns(),
        actionMenuItems: [],
        selectedStudents: [],
        isAssignAdvisor: false,
        errorMessage: ''
    };

    componentDidMount() {
        this.loadActionMenuItems();
        this.loadPageTitle();
    }

    //θ(1) Toggles isLoading
    toggleIsLoading = (isLoading = !this.state.isLoading) => {
        this.setState({
            isLoading
        })
    }

    //θ(1) Toggles isSearchResult
    toggleIsSearchResult = (isSearchResult = !this.state.isSearchResult) => {
        this.setState({
            isSearchResult
        })
    }

    //θ(1) Toggles isAssignAdvisor
    toggleIsAssignAdvisor = (isAssignAdvisor = !this.state.isAssignAdvisor) => {
        this.setState({
            isAssignAdvisor
        })
    }

    //θ(N) where N is the number of pages in PageDetails
    //Retrieve page title from pageDetails using pageid
    loadPageTitle = () => {
        const page = Object.values(PageDetails).find(page => page.id === this.state.id);
        this.setState({
            title: page.title
        })
    }

    //θ(1) Sets action menu items
    loadActionMenuItems = () => {
        const {id} = this.state;

        const actionMenuItems = [
            {
                id: `${id}__menuItem_assignNewAdvsior`,
                label: 'Assign',
                onClick: () => {this.toggleIsAssignAdvisor();}
            }
        ];

        this.setState({
            actionMenuItems
        })
    }

    //θ(1) Search students for seleted advisors
    //TODO add code to call search api
    searchStudents = (selectedValues) => {
        //Set searched department as selected department as users may change department while UI showing data of previous department
        const {department} = selectedValues;
        this.setState({
            activeDepartment: department,
            students: []
        });
        this.toggleIsSearchResult(true);
        this.loadStudents(department);
    }

    //θ(1) Loads students for selected department
    loadStudents = (department = this.state.activeDepartment) => {
        this.toggleIsLoading(true);
        this.setState({
            students: []
        })
        const departmentCode = department.STVDEPT_CODE;
        getData(
            sam.unassignedStudents(departmentCode),
            true
        )
        .then(students => {
            students = formatStudents(students);
            this.setState({
                students,
                errorMessage: ''
            })
        })
        .catch(err => {
            console.log(err);
            this.setState({
                errorMessage: 'We’re currently experiencing an issue retrieving student data. Please try again later.'
            });
        })
        .finally(_ => {
            this.toggleIsLoading(false);
        })
    }

    //θ(1) Sets the selected students from datagrid
    onStudentSelected = (selectedStudents) => {
        this.setState({
            selectedStudents
        })
    }

    //θ(1) Assign new advisor to students
    onAssignAdvisor = (selectedValued) => {
        this.toggleIsLoading(true);
        const {advisor} = selectedValued;
        const students = createSelectedStudentObject(this.state.students, this.state.selectedStudents);
        
        const body = {
            students,
            newAdvisorPidm: advisor.SIRDPCL_PIDM,
        }

        postData(
            sam.assignStudent,
            body,
            true
        )
        .then(result => {
            toast.success('New Advisor Assigned');
            this.toggleIsAssignAdvisor(false);
            this.toggleIsLoading(false);
            this.loadStudents();
        })
        .catch(err => {
            console.log(err);
            toast.error('Unable to assign new advisor');
            this.toggleIsLoading(false);
        })
    }

    render() {
        const {id} = this.state;
        return <React.Fragment>
            <div
                id={`${id}__div_pageWrapper`}
                className={`myOdu__${id}`}
            >
                <Container id = {`${id}__container_pageWrapper`} className="myOdu__container secondaryPage">
                    <PageHeader 
                        id = {this.state.id}
                        title = {this.state.title} 
                        isSubPage
                    />

                    <Box id = {`${id}__box_wrapper`} className = {'wrapper'}>
                        <SAMFilter
                            id = {id}

                            isDepartmentCode
                            isDepartmentRequired
                            departmentsLength = {{xs: 12, xl: 5}}

                            primaryButtonText = {'Search'}
                            onPrimary = {this.searchStudents}
                            disablePrimaryButton = {this.state.isLoading}
                            primaryButtonLength = {{xs: 12, xl: 1}}

                            widgetName = {this.state.title}
                        />
                        <AssignAdvisorDialog 
                            isAssignAdvisor = {this.state.isAssignAdvisor}
                            toggleIsAssignAdvisor = {this.toggleIsAssignAdvisor}

                            selectedStudents = {this.state.selectedStudents}
                            onAssignAdvisor = {this.onAssignAdvisor}

                            disablePrimaryButton = {this.state.isLoading}
                        />
                        {
                            this.state.isSearchResult  &&
                            <StudentDetails
                                id = {`${id}Details`}
                                students = {this.state.students}
                                columns = {this.state.columns}
                                onStudentSelected = {this.onStudentSelected}

                                actionMenuItems = {this.state.actionMenuItems}
                                disableActionButton = {this.state.selectedStudents.length === 0}

                                noStudentMessage = {
                                    this.state.errorMessage.length ? 
                                    this.state.errorMessage :
                                    <>There are no unassigned <strong>{this.state.activeDepartment.STVDEPT_DESC}</strong> students.</>}
                                message = {`Unassigned Advisees for ${this.state.activeDepartment.STVDEPT_DESC}`}
                                isLoading = {this.state.isLoading}
                            />
                        }
                    </Box>
                </Container>
            </div>
        </React.Fragment>
    }

}

const mapStateToProps = state => {
    return {
        user: state.AWSReducer.user,
        departments: state.samReducer.departments,
        advisors: state.samReducer.advisors,
        advisorsByDept: state.samReducer.advisorsByDept,
        advisorTypes: state.samReducer.advisorTypes
    };
};

export default connect(mapStateToProps)(UnassignedStudents);