import React, {Component} from 'react';
import {
    Stack,
    Box,
    InputAdornment,
    Typography,
    Autocomplete,
    TextField,
    Card,
    CardActionArea
} from '@mui/material';
import {AppConfigs} from '../SetUp/config';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMagnifyingGlass, faXmark} from '@fortawesome/pro-light-svg-icons';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

class SearchAppsAutocomplete extends Component {
    state = {
        searchText: '',
        options: []
    };

    componentDidMount() {
        this.setState({
            options: AppConfigs.Apps.map(app => ({
                ...app,
                label: app.name + app.desc
            }))
        });
    }

    onTextChange = (e, text) => {
        this.setState({searchText: text});
    };

    onAppChange = (e, app) => {
        this.props.saveApplicationsFilter(app ?? '');
    };

    // toggleFocus = () =>{
    //     this.setState({onFocus: !this.state.onFocus});
    // };

    render() {
        const imgPath = '/img/logos/';
        return (
            <React.Fragment>
                <Autocomplete
                    className={
                        'myOdu__hideFloatLabel myOdu__searchAppsAutocomplete ' +
                        (this.props.isTopNav ? 'topNav ' : '') +
                        ' ' +
                        (this.props.isMobile ? 'mobile ' : '') +
                        ' ' +
                        (this.props.isSearchOnFocus ? ' onFocus' : '')
                    }
                    onBlur={this.props.toggleFocus}
                    onFocus={this.props.toggleFocus}
                    options={this.state.options}
                    forcePopupIcon={false}
                    inputValue={this.props.searchText}
                    onInputChange={this.onTextChange}
                    value={this.props.text}
                    onChange={this.onAppChange}
                    open={this.props.isSearchOnFocus && this.state.searchText}
                    renderInput={params => (
                        <TextField
                            {...params}
                            fullWidth
                            size="small"
                            label="Search"
                            placeholder="Search for applications"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <InputAdornment
                                        className="cursor-pointer"
                                        position="end"
                                    >
                                        {/* <InputAdornment className="cursor-pointer" position="end" onClick={this.props.toggleOpen}> */}
                                        {/* <FontAwesomeIcon icon={this.props.isTopNav ? faXmark : faMagnifyingGlass} /> */}
                                        <FontAwesomeIcon
                                            icon={faMagnifyingGlass}
                                        />
                                    </InputAdornment>
                                )
                            }}
                        />
                    )}
                    renderOption={(props, option, state) => {
                        const nameMatches = match(
                            option.name,
                            state.inputValue,
                            {
                                insideWords: true
                            }
                        );
                        const nameParts = parse(option.name, nameMatches);

                        const descMatches = match(
                            option.desc,
                            state.inputValue,
                            {
                                insideWords: true
                            }
                        );
                        const descParts = parse(option.desc, descMatches);

                        return (
                            <Card sx={{borderRadius: '0', width: '100%'}}>
                                <CardActionArea
                                    sx={{p: 1}}
                                    alignItems="center"
                                    href={option.link}
                                >
                                    <Stack
                                        direction="row"
                                        alignItems={'center'}
                                    >
                                        <Box
                                            sx={{
                                                height: '40px',
                                                width: '40px',
                                                minWidth: '40px',
                                                backgroundColor: 'white',
                                                m: 1
                                            }}
                                            className="myOdu__logoZoom"
                                        >
                                            <img
                                                src={imgPath + option.icon}
                                                alt={option.name}
                                            />
                                        </Box>
                                        <Stack>
                                            {/* App Name */}
                                            <Typography>
                                                <div>
                                                    {nameParts.map(
                                                        (part, index) => (
                                                            <span
                                                                key={index}
                                                                style={{
                                                                    fontWeight:
                                                                        part.highlight
                                                                            ? 700
                                                                            : 400
                                                                }}
                                                            >
                                                                {part.text}
                                                            </span>
                                                        )
                                                    )}
                                                </div>
                                            </Typography>
                                            {/* App Description */}
                                            <Typography
                                                variant="smaller"
                                                sx={{color: 'black'}}
                                            >
                                                <div>
                                                    {descParts.map(
                                                        (part, index) => (
                                                            <span
                                                                key={index}
                                                                style={{
                                                                    fontWeight:
                                                                        part.highlight
                                                                            ? 700
                                                                            : 400
                                                                }}
                                                            >
                                                                {part.text}
                                                            </span>
                                                        )
                                                    )}
                                                </div>
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </CardActionArea>
                            </Card>
                        );
                    }}
                />
            </React.Fragment>
        );
    }
}

// const mapStateToProps = (state) => {
//     return {
//         text: state.applicationsReducer.text
//     }
//   }

// const mapDispatchToProps = (dispatch) => ({
//     saveApplicationsFilter: (text) => dispatch(saveApplicationsFilter(text))
// });

// export default connect(mapStateToProps, mapDispatchToProps)(Head);

export default SearchAppsAutocomplete;
