import {Stack, Grid, Typography} from '@mui/material';
import React, {Component} from 'react';
import {Col, Row} from 'reactstrap';
import {RecurringOptions} from './config';
import {
    FormDropDown,
    FormDropDownUnwrapped
} from '../../../../../common/Form/FormDropdown';
import {FormCheckbox} from '../../../../../common/Form/FormCheckbox';
import {FormRadioButton} from '../../../../../common/Form/FormRadioButton';
import {FormDatePicker} from '../../../../../common/Form/FormDatePicker';
import dayjs from 'dayjs';
import {Time} from '../../../../../common/MomentTime';

class RepeatMeetings extends Component {
    state = {};

    //Θ(1) Sets value for selects
    onSelectChange = event => {
        let recurring = {...this.props.recurring};

        recurring[event.target.name] = event.target.value;

        this.props.setRecurring(recurring);
    };

    //Θ(1) Sets date
    onDateChange = value => {
        let recurring = {...this.props.recurring};
        recurring.byDate = value;

        this.props.setRecurring(recurring);
    };

    //Θ(1) Sets checkbox value for days in week
    onWeekday = event => {
        let recurring = {...this.props.recurring};
        if (event.target.checked)
            recurring.weekdays[event.target.name] = event.target.checked;
        else delete recurring.weekdays[event.target.name];

        this.props.setRecurring(recurring);
    };

    //Θ(1) Sets byDay or byWeek in Month
    onMonthOccurs = event => {
        let recurring = {...this.props.recurring};
        recurring.monthOccursOn = event.target.value;
        this.props.setRecurring(recurring);
    };

    render() {
        const recurrenceLength =
            (this.props.recurrence.value === RecurringOptions.daily.value &&
                this.props.recurrence.recurrenceLength) ||
            Time.University(this.props.course.END_DATE).diff(
                Time.University(this.props.course.START_DATE),
                this.props.recurrence.recurrenceValue
            );
        return (
            <React.Fragment>
                {/* For each type */}
                {/* <Box> */}
                <Row style={{padding: 4}}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid
                            item
                            xs={12}
                            md={2}
                            textAlign={{xs: 'start', md: 'end'}}
                        >
                            <Typography className="myOdu__label">
                                Every
                            </Typography>
                        </Grid>
                        <Grid item xs={'auto'}>
                            <FormDropDown
                                options={Array(recurrenceLength)
                                    .fill(0)
                                    .map((_, idx) => {
                                        return {
                                            label: idx + 1,
                                            value: idx + 1
                                        };
                                    })}
                                labelGridProps={{xs: 0}}
                                inputGridProps={{xs: 12}}
                                onChange={this.onSelectChange}
                                id="select-repeats"
                                name={RecurringOptions.repeatsEvery}
                                value={
                                    this.props.recurring[
                                        RecurringOptions.repeatsEvery
                                    ]
                                }
                            />
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            textAlign={'start'}
                            alignItems={'center'}
                        >
                            <Typography variant="p">
                                {this.props.recurrence.displayHelper}
                            </Typography>
                        </Grid>
                    </Grid>
                </Row>

                {/* Weekly */}
                {this.props.recurrence.value ===
                    RecurringOptions.weekly.value && (
                    <Row style={{padding: 4}}>
                        <Grid container spacing={1}>
                            <Grid
                                item
                                xs={12}
                                md={2}
                                textAlign={{xs: 'start', md: 'end'}}
                            >
                                <Typography className="myOdu__label">
                                    Days
                                </Typography>
                            </Grid>
                            <Grid item alignItems="center">
                                {Object.entries(
                                    RecurringOptions.weekly.weekdays
                                ).map(([key, value]) => {
                                    const isChecked =
                                        this.props.recurring.weekdays[value.id];
                                    return (
                                        <FormCheckbox
                                            label={value.name}
                                            onChange={this.onWeekday}
                                            name={value.id}
                                            labelGridProps={{
                                                xs: 0
                                            }}
                                            value={isChecked}
                                            inputGridProps={{
                                                xs: 12,
                                                md: 9,
                                                lg: 8,
                                                xl: 9
                                            }}
                                            sx={{marginRight: '0.25rem'}}
                                        />
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </Row>
                )}

                {/* Monthly */}
                {this.props.recurrence.value ===
                    RecurringOptions.monthly.value && (
                    <>
                        <Row className="mt-2">
                            <Col
                                xs={12}
                                sm={2}
                                className="text-start text-sm-end pe-0"
                            >
                                <Typography className="myOdu__label">
                                    Occurs on
                                </Typography>
                            </Col>

                            <Col className="mt-2" xs={12} sm={10}>
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                >
                                    <FormRadioButton
                                        options={[
                                            {
                                                label: '',
                                                value: RecurringOptions.monthly
                                                    .occursOnDay,
                                                checked:
                                                    this.props.recurring
                                                        .monthOccursOn ===
                                                    RecurringOptions.monthly
                                                        .occursOnDay
                                            }
                                        ]}
                                        labelGridProps={{xs: 0}}
                                        inputGridProps={{xs: 0, m: 0, p: 0}}
                                        onChange={this.onMonthOccurs}
                                    />
                                    <Typography variant="small">day</Typography>
                                    <FormDropDownUnwrapped
                                        options={Array(
                                            this.props.recurrence
                                                .daysOfMonthLength
                                        )
                                            .fill(0)
                                            .map((_, idx) => {
                                                return {
                                                    label: idx + 1,
                                                    value: idx + 1
                                                };
                                            })}
                                        labelGridProps={{xs: 0}}
                                        inputGridProps={{xs: 2}}
                                        onChange={this.onSelectChange}
                                        id="select-day-of-month"
                                        name={
                                            RecurringOptions.monthly.monthlyDay
                                        }
                                        value={
                                            this.props.recurring[
                                                RecurringOptions.monthly
                                                    .monthlyDay
                                            ]
                                        }
                                        fullWidth
                                    />

                                    <Typography variant="p">
                                        {
                                            this.props.recurrence
                                                .occursOnDisplayHelper
                                        }
                                    </Typography>
                                </Stack>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="mt-2 offset-sm-2" xs={12} sm={10}>
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                    sx={{width: '100%'}}
                                >
                                    <FormRadioButton
                                        options={[
                                            {
                                                label: '',
                                                value: RecurringOptions.monthly
                                                    .occursOnWeek,
                                                checked:
                                                    this.props.recurring
                                                        .monthOccursOn ===
                                                    RecurringOptions.monthly
                                                        .occursOnWeek
                                            }
                                        ]}
                                        labelGridProps={{xs: 0}}
                                        inputGridProps={{xs: 0, m: 0, p: 0}}
                                        onChange={this.onMonthOccurs}
                                    />

                                    <Typography variant="p">the</Typography>
                                    <FormDropDownUnwrapped
                                        id="select-week-frequency"
                                        onChange={this.onSelectChange}
                                        name={
                                            RecurringOptions.monthly.monthlyWeek
                                        }
                                        value={
                                            this.props.recurring[
                                                RecurringOptions.monthly
                                                    .monthlyWeek
                                            ]
                                        }
                                        options={this.props.recurrence.weekFrequency.map(
                                            (frequency, idx) => {
                                                return {
                                                    label: frequency,
                                                    value: idx
                                                };
                                            }
                                        )}
                                        labelGridProps={{xs: 0}}
                                        inputGridProps={{xs: 1}}
                                    />

                                    <FormDropDownUnwrapped
                                        id="select-month-weekday"
                                        fullWidth
                                        onChange={this.onSelectChange}
                                        name={
                                            RecurringOptions.monthly
                                                .monthlyWeekday
                                        }
                                        value={
                                            this.props.recurring[
                                                RecurringOptions.monthly
                                                    .monthlyWeekday
                                            ]
                                        }
                                        options={this.props.recurrence.weekdays.map(
                                            (weekday, idx) => {
                                                return {
                                                    label: weekday,
                                                    value: idx
                                                };
                                            }
                                        )}
                                        labelGridProps={{xs: 0}}
                                        inputGridProps={{xs: 12}}
                                    />

                                    <Typography variant="p">
                                        {
                                            this.props.recurrence
                                                .occursOnDisplayHelper
                                        }
                                    </Typography>
                                </Stack>
                            </Col>
                        </Row>
                    </>
                )}

                {/* For each */}
                <Row style={{padding: 4}} className="mt-2">
                    <FormDatePicker
                        value={this.props.meetingDates[0]}
                        onChange={this.props.onStartDateChange}
                        label={'Start'}
                        inputGridProps={{xs: 12, md: 4}}
                        labelGridProps={{xs: 12, md: 2}}
                        minDate={dayjs(
                            this.props.termStartDate ??
                                this.props.course.START_DATE
                        )}
                        maxDate={dayjs(
                            this.props.termEndDate ?? this.props.course.END_DATE
                        )}
                    />
                </Row>

                {/* For each */}
                <Row style={{padding: 4}}>
                    <Grid
                        container
                        spacing={{xs: 0, sm: 1}}
                        alignItems="center"
                    >
                        <Grid
                            item
                            xs={12}
                            md={2}
                            textAlign={{xs: 'start', md: 'end'}}
                        >
                            <Typography className="myOdu__label">
                                End
                            </Typography>
                        </Grid>
                        <Grid item container xs={12} md={10} spacing={1}>
                            <Grid item xs={'auto'} style={{paddingRight: 0}}>
                                <FormDropDown
                                    id="select-end"
                                    fullWidth
                                    onChange={this.onSelectChange}
                                    name={'endDate'}
                                    value={this.props.recurring['endDate']}
                                    options={[
                                        {
                                            label: 'By',
                                            value: RecurringOptions.endDateBy
                                        },
                                        {
                                            label: 'After',
                                            value: RecurringOptions.endDateAfter
                                        }
                                    ]}
                                    labelGridProps={{xs: 0}}
                                    inputGridProps={{xs: 'auto'}}
                                />
                            </Grid>
                            {this.props.recurring.endDate ===
                                RecurringOptions.endDateBy && (
                                <Grid item xs={8} md={'auto'}>
                                    <FormDatePicker
                                        value={this.props.recurring.byDate}
                                        onChange={this.onDateChange}
                                        inputGridProps={{xs: 12}}
                                        labelGridProps={{xs: 0}}
                                        minDate={dayjs(
                                            this.props.termStartDate ??
                                                this.props.course.START_DATE
                                        )}
                                        maxDate={dayjs(
                                            this.props.termEndDate ??
                                                this.props.course.END_DATE
                                        )}
                                        error={
                                            this.props.recurring.byDate.diff(
                                                this.props.meetingDates[0],
                                                'day'
                                            ) < 0
                                        }
                                        errorText={
                                            'End date must be after start'
                                        }
                                    />
                                </Grid>
                            )}
                            {this.props.recurring.endDate ===
                                RecurringOptions.endDateAfter && (
                                <Grid container item xs={8} spacing={1}>
                                    <Grid item xs={'auto'}>
                                        <FormDropDown
                                            id="select-occurence"
                                            name={RecurringOptions.occurence}
                                            value={
                                                this.props.recurring[
                                                    RecurringOptions.occurence
                                                ]
                                            }
                                            onChange={this.onSelectChange}
                                            options={Array(
                                                this.props.recurrence
                                                    .occurenceLength
                                            )
                                                .fill(0)
                                                .map((_, idx) => {
                                                    return {
                                                        label: idx + 1,
                                                        value: idx + 1
                                                    };
                                                })}
                                            inputGridProps={{xs: 12}}
                                            labelGridProps={{xs: 0}}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={'auto'}
                                        textAlign={'center'}
                                        alignContent={'center'}
                                    >
                                        <Typography variant="p">
                                            occurrences
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Row>
                {/* </Box> */}
            </React.Fragment>
        );
    }
}

export default RepeatMeetings;
