const Apps = [
    {
        id: 'canvas',
        name: 'Canvas',
        desc: 'Canvas is ODU\'s web-based learning management system used by instructors and students to access and manage online course learning materials.',
        icon: 'canvas.svg',
        link: 'https://canvas.odu.edu'
    },
    {
        id: 'leoOnline',
        name: 'LEO Online',
        desc: 'Leo Online is a secure, web-based self-service tool designed for students, faculty, and staff access to registration, grades, and records associated with academic progress and employment.',
        icon: 'leoOnline.svg',
        link: 'https://ssb-prod.ec.odu.edu/ssomanager/saml/login?relayState=/c/auth/SSB'
    },
    {
        id: 'zoom',
        name: 'Zoom',
        desc: 'Zoom is a video conferencing platform for online meetings, classes, and University events via desktop or mobile app.',
        icon: 'zoom.svg',
        link: 'https://odu.zoom.us/'
    },
    {
        id: 'oduMobile',
        name: 'ODUMobile',
        desc: 'ODUMobile is a free mobile application geared toward providing information on the go and allows users access to essential Old Dominion University information anytime on their mobile device.',
        icon: 'oduMobileIcon.svg',
        link: 'https://ww1.odu.edu/ts/software-services/odumobile'
    },
    {
        id: 'courseSearch',
        name: 'ODU Course Search',
        icon: 'courseSearch.svg',
        desc: 'The ODU course search tool helps students find courses by subject, instructor, or schedule, with details on descriptions, prerequisites, and enrollment.',
        link: 'https://catalog.odu.edu/course-search/',
    },
    {
        id: 'degreeWorks',
        name: 'Degree Works',
        desc: 'The Degree Works degree evaluation tool is designed to assist advisors and students with course planning, measuring progress toward program completion, and curriculum advising.',
        icon: 'degreeWorks.svg',
        link: 'https://dw-prod.ec.odu.edu/responsiveDashboard'
    },
    {
        id: 'outlook',
        name: 'Outlook',
        desc: 'Old Dominion University uses Microsoft Outlook as the official e-mail system for distributing course-related communications, policies, announcements and other information.',
        icon: 'outlook.svg',
        link: 'https://outlook.odu.edu/'
    },
    {
        id: 'office365',
        name: 'Office 365',
        desc: 'Office 365 is an office suite of products that include cloud collaboration tools and applications, provided for no cost, as long as users are a part of the University.',
        icon: 'office.svg',
        link: 'https://office365.odu.edu/'
    },
    
]

export default Apps