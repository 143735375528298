import {SiteCodeCampusMapping} from './config';

//θ(N) where N is number of students
//Maps the selected students and forms the body {pidm, level, primInd} for each student
const createSelectedStudentObject = (students, selectedStudents = []) => {
    const studentsObj = {};

    students.forEach(student => {
        studentsObj[student.SPRIDEN_PIDM] = student;
    });

    const studentsData = selectedStudents.map(studentPIDM => {
        // const student = students.find(student => student.SPRIDEN_PIDM === studentPIDM);
        const student = studentsObj[studentPIDM];
        return {
            pidm: student.SPRIDEN_PIDM,
            level: student.SGBSTDN_LEVL_CODE,
            primInd: student.SGRADVR_PRIM_IND
        };
    });

    return studentsData;
};

/**
 * O(1), Gets the campus details based on the site code
 * @param {number} siteCode The site code
 * @return {string} The campus details
 */
const getCampusFromSiteCode = siteCode => {
    return SiteCodeCampusMapping[siteCode] ?? 'Unknown';
};

//O(N) Where N is the number of students
//Creates the student data with departments, types and field of studies
const formatStudents = (students) => {
    return students.map(student => {
        const {departments, types, fields} = formatStudent(student);
        return {
            ...student,
            DEPARTMENTS: departments,
            TYPES: types,
            FIELD_OF_STUDIES: fields
        }
    })
}

/**
 * O(1) Creates the student data with departments, types and field of studies
 * @param {student} student 
 * @returns {departments, types, fieldOfStudies}
 */
const formatStudent = (student) => {
    const departments = [];
    const types = [];
    const fields = [];

    departments.push(student.DEPT_CODE || student.STDN_DEPT_CODE + ' - ' + student.DEPT_DESC);
    types.push('Major');
    fields.push(student.MAJR_CODE_1 + ' - ' + student.MAJR_CODE_1_DESC);

    if(student?.MAJR_CODE_CONC_1) {
        departments.push('\u00A0');
        types.push('Major');
        fields.push(student.MAJR_CODE_CONC_1 + ' - ' + student.MAJR_CODE_CONC_1_DESC);
    }

    if(student?.MAJR_CODE_CONC_1_2) {
        departments.push('\u00A0');
        types.push('Major');
        fields.push(student.MAJR_CODE_CONC_1_2 + ' - ' + student.MAJR_CODE_CONC_1_2_DESC);
    }

    if(student?.MAJR_CODE_CONC_1_3) {
        departments.push('\u00A0');
        types.push('Major');
        fields.push(student.MAJR_CODE_CONC_1_3 + ' - ' + student.MAJR_CODE_CONC_1_3_DESC);
    }

    if(student?.MAJR_CODE_MINR_1) {
        departments.push('\u00A0');
        types.push('Minor');
        fields.push(student.MAJR_CODE_MINR_1 + ' - ' + student.MAJR_CODE_MINR_1_DESC);
    }

    if(student?.MAJR_CODE_MINR_1_2) {
        departments.push('\u00A0');
        types.push('Minor');
        fields.push(student.MAJR_CODE_MINR_1_2 + ' - ' + student.MAJR_CODE_MINR_1_2_DESC);
    }

    if(student?.DEPT_CODE_2) {
        departments.push(student.DEPT_CODE_2 + ' - ' + student.DEPT_CODE_2_DESC);
        types.push('Major');
        fields.push(student.MAJR_CODE_2 + ' - ' + student.MAJR_CODE_2_DESC);
    }   
    
    if(student?.MAJR_CODE_CONC_2) {
        departments.push('\u00A0');
        types.push('Major');
        fields.push(student.MAJR_CODE_CONC_2 + ' - ' + student.MAJR_CODE_CONC_2_DESC);
    }

    if(student?.MAJR_CODE_CONC_2_2) {
        departments.push('\u00A0');
        types.push('Major');
        fields.push(student.MAJR_CODE_CONC_2_2 + ' - ' + student.MAJR_CODE_CONC_2_2_DESC);
    }

    if(student?.MAJR_CODE_CONC_2_3) {
        departments.push('\u00A0');
        types.push('Major');
        fields.push(student.MAJR_CODE_CONC_2_3 + ' - ' + student.MAJR_CODE_CONC_2_3_DESC);
    }

    if(student?.MAJR_CODE_MINR_2) {
        departments.push('\u00A0');
        types.push('Minor');
        fields.push(student.MAJR_CODE_MINR_2 + ' - ' + student.MAJR_CODE_MINR_2_DESC);
    }

    if(student?.MAJR_CODE_MINR_2_2) {
        departments.push('\u00A0');
        types.push('Minor');
        fields.push(student.MAJR_CODE_MINR_2_2 + ' - ' + student.MAJR_CODE_MINR_2_2_DESC);
    }

    return {
        departments,
        types,
        fields
    };
}

export {createSelectedStudentObject, formatStudents, getCampusFromSiteCode};
