import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function TabPanel(props) {
    const {children, value, index, id, lablledby, className, ...other} = props;

    return (
        <div
            className={'myOdu__tabPanel ' + className ?? ''}
            role="tabpanel"
            hidden={value !== index}
            id={id}
            aria-labelledby={lablledby}
            {...other}
        >
            {value === index && (
                <Box id={id + '__box_tabWrapper'} sx={{p: 0}}>
                    <div id={id + '__div_tabPanel'}>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};
