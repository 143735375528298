export const MYAPPS_LEFT_PANEL = 'myAppsLeftPanel';
export const MYAPPS_RIGHT_PANEL = 'myAppsRightPanel';
export const MYAPPS_CENTER_PANEL = 'myAppsCenterPanel';
export const MYAPPS_DIRECTIONS = {
    LEFT: 'LEFT',
    RIGHT: 'RIGHT',
    UP: 'UP',
    DOWN: 'DOWN'
};
//ITEMS_COUNT_PANEL must be divisible by ITEM_COUNT_ROW
export const MYAPPS_ITEMS_COUNT_PANEL = 9;
export const MYAPPS_ITEMS_COUNT_ROW = 3;
