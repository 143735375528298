import {SAVE_TERMS} from './actions';

const initialStateTerms = {
    terms: {},
    updatedAt: null
};

// Persists terms across pages
export const termsReducer = (state = initialStateTerms, action) => {
    const {type, payload} = action;
    switch (type) {
        case SAVE_TERMS: {
            return {...state, terms: payload.terms, updatedAt: Date.now()};
        }
        default:
            return state;
    }
};
