import React, {Component} from 'react';
import {DataGrid} from '@mui/x-data-grid';
import {getData, postData} from '../../DataAccessLayer';
import {myCourses, terms} from '../../DataAccessLayer/services';
import Widget from '../common/Widget';
import {getTakingColumns, getTeachingColumns, getRowHeight} from './columns';
import {
    Tab,
    Tabs,
    Box,
    FormGroup,
    Switch,
    Stack,
    Typography,
    Divider,
    LinearProgress,
    Skeleton
} from '@mui/material/';
import TabPanel from '../common/TabPanel';
import CCT from './CCT';
import {connect} from 'react-redux';
import {toggleCCT} from './CCT/actions';
import {saveMyCoursesTaking, saveMyCoursesTeaching} from './actions';
import {saveTerms} from './Terms/actions';
import PortalTooltip from '../common/PortalTooltip';
import {FormDropDown} from '../common/Form/FormDropdown';
import {columnsToHideForMobile} from './config';
import {WithSmallScreen} from '../common/WithSmallScreen';
import {isUserAdmin} from '../Layout/utils';
import {Time} from '../common/MomentTime';
import ApiErrorMessage from '../common/ApiErrorMessage';

class MyCourses extends Component {
    state = {
        // isExpand: false,
        isLoading: false,
        isError: false,
        teachingCourses: {},
        takingCourses: {},
        isTeachingSelected: true,
        selectedTab: 1,
        isCctModalOpen: false,
        terms: []
        //TODO: enable in V1.1
        // menu: [
        //     {id: 'myCourses__hide', title: 'Hide'}
        // ]
    };

    toggleIsLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading
        });
    };

    toggleCctIsModalOpen = () => {
        this.setState({isCctModalOpen: !this.state.isCctModalOpen});
    };

    componentDidMount() {
        this.loadTermCourseDetails();
    }


    //Θ(1) Loads courses from backend
    loadTermCourseDetails = () => {
        this.toggleIsLoading();
        this.setState({isError: false});
        if (
            !this.props.termsUpdatedAt ||
            Time.University(this.props.termsUpdatedAt).format('YYYY-MM-DD') !==
                Time.University().format('YYYY-MM-DD') || !this.props.terms['CURRENT_TERM']
        ) {
            getData(terms, true)
                .then(terms => {
                    this.setState({terms});
                    this.props.saveTerms(terms);
                    this.loadCourses(terms)
                        .then(result => result)
                        .catch(err => {
                            console.error('Error loading courses in if:', err);
                            this.setState({isError: true});
                        });
                })
                .catch(err => {
                    console.error('Error loading terms:', err);
                    this.setState({isError: true});
                })
                .finally(() => {
                    this.toggleIsLoading();
                });
        } else {
            this.setState({terms: this.props.terms});
            this.loadCourses(this.props.terms)
                .then(result => result)
                .catch(err => {
                    console.error('Error loading courses in else:', err);
                    this.setState({isError: true});
                })
                .finally(() => this.toggleIsLoading());
        }
    };

    //Θ(1) Loads courses from backend
    loadCourses = terms => {
        return new Promise((resolve, reject) => {
            if (!terms) {
                reject(new Error('terms undefined - cannot load courses'));
            }
            postData(
                myCourses,
                Object.values(terms)?.map(term => term.code)
            )
                .then(courses => {
                    this.setState({
                        courses,
                        isTeachingSelected: !!courses.teaching?.length
                    });
                    this.props.saveMyCoursesTaking(courses.taking);
                    this.props.saveMyCoursesTeaching(courses.teaching);
                    const finalCourses = Object.values(terms).reduce(
                        (prev, term) => {
                            const teachingCourses = courses.teaching.filter(
                                course => course.SSBSECT_TERM_CODE === term.code
                            );
                            const takingCourses = courses.taking.filter(
                                course => course.TERM_CODE === term.code
                            );
                            prev.teachingCourses[term.code] = teachingCourses;
                            prev.takingCourses[term.code] = takingCourses;
                            return prev;
                        },
                        {teachingCourses: {}, takingCourses: {}}
                    );
                    this.setState({
                        takingCourses: finalCourses.takingCourses,
                        teachingCourses: finalCourses.teachingCourses
                    });
                    resolve();
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    toggleIsTeaching = (event, isTeachingSelected) => {
        this.setState({isTeachingSelected: !isTeachingSelected});
    };

    setSelectedTab = (event, newValue) => {
        this.setState({selectedTab: newValue});
    };

    render() {
        const options = Object.values(this.state.terms)
        return (
            <React.Fragment>
                {/* OPTIMIZE: CCT meeting should be closed first and then the redux store must be updated */}
                {this.props.isCCT && <CCT />}
                <Widget
                    data={{
                        id: this.props.widget.id,
                        isTitleStylized: this.props.isTitleStylized,
                        title: this.props.title,
                        menu: this.state.menu,
                        isExpand: this.props.widget.isExpand,
                        isRequired: this.props.widget.isRequired
                    }}
                    isExpand={this.state.isExpand}
                    {...this.props}
                    className="myOdu__myCourses"
                >
                    {this.state.isError && (
                        <ApiErrorMessage
                            widgetName={this.props.title}
                            reload={this.loadTermCourseDetails}
                        />
                    )}
                    {this.state.isLoading && (
                        <Skeleton variant="rectangular" height={98} />
                    )}
                    {!this.state.isLoading && !this.state.isError && (
                        <div>
                            {this.state.courses?.teaching?.length > 0 &&
                                this.state.courses?.taking?.length > 0 && (
                                    <>
                                        <Stack
                                            direction="row"
                                            justifyContent={'flex-start'}
                                            sx={{
                                                width: '100%',
                                                backgroundColor: '#FAFAFA',
                                                pl: 1
                                            }}
                                        >
                                            <FormGroup>
                                                <Stack
                                                    direction="row"
                                                    spacing={1}
                                                    alignItems="center"
                                                >
                                                    <label className="myOdu__toggleSwitchLabel">
                                                        <Typography
                                                            variant="smaller"
                                                            id="coursesViewByLabel"
                                                        >
                                                            Courses
                                                        </Typography>
                                                    </label>
                                                    <Typography variant="smaller">
                                                        Teaching
                                                    </Typography>
                                                    <Switch
                                                        id="myCourses__switch_toggleTeachingTaking"
                                                        size="small"
                                                        color="primary"
                                                        inputProps={{
                                                            'aria-label':
                                                                'Courses'
                                                        }}
                                                        value={
                                                            this.state
                                                                .isTeachingSelected
                                                        }
                                                        onChange={
                                                            this
                                                                .toggleIsTeaching
                                                        }
                                                    />
                                                    <Typography variant="smaller">
                                                        Taking
                                                    </Typography>
                                                </Stack>
                                            </FormGroup>
                                        </Stack>
                                    </>
                                )}
                            <Divider />

                            <div>
                                <Box
                                    sx={{
                                        borderBottom: 1,
                                        borderColor: 'divider'
                                    }}
                                >
                                    {this.props.isSmall ? (
                                        <FormDropDown
                                            sx={{mt: '1rem'}}
                                            options={options.map(
                                                (term, index) => ({
                                                    value: index,
                                                    label:
                                                        term.title
                                                            .charAt(0)
                                                            .toLocaleUpperCase() +
                                                        term.title
                                                            .slice(1)
                                                            .toLowerCase()
                                                })
                                            )}
                                            value={this.state.selectedTab}
                                            onChange={e =>
                                                this.setSelectedTab(
                                                    null,
                                                    e.target.value
                                                )
                                            }
                                            ariaLabel={'myCourses dropdown'}
                                        />
                                    ) : (
                                        <Tabs
                                            className="myOdu__tabs myCourses"
                                            value={this.state.selectedTab}
                                            onChange={this.setSelectedTab}
                                            aria-label={'Choose Semester'}
                                        >
                                            {options.map(
                                                (term, index) => (
                                                    <PortalTooltip
                                                        key={
                                                            'myCourses__tab_tooltip_' +
                                                            index
                                                        }
                                                        title={`Academic Year ${term.code.substring(
                                                            0,
                                                            4
                                                        )}`}
                                                    >
                                                        <Tab
                                                            key={
                                                                'myCourses__tab_' +
                                                                index
                                                            }
                                                            label={term.title}
                                                            id={
                                                                'myCourses__tab_' +
                                                                term.code
                                                            }
                                                            aria-controls={
                                                                'myCourses__tabPanel_' +
                                                                term.code
                                                            }
                                                        />
                                                    </PortalTooltip>
                                                )
                                            )}
                                        </Tabs>
                                    )}
                                </Box>

                                {options.map((term, index) => {
                                    return (
                                        <TabPanel
                                            key={'myCourses__tabPanel_' + index}
                                            value={this.state.selectedTab}
                                            index={index}
                                            id={
                                                'myCourses__tabPanel_' +
                                                term.code
                                            }
                                            className="myOdu__tabPanel myCourses pt-0 px-0"
                                            lablledby={
                                                'myCourses__tab_' + term.code
                                            }
                                        >
                                            <Box className="wrapper">
                                                <DataGrid
                                                    getRowHeight={getRowHeight}
                                                    rows={
                                                        (this.state
                                                            .isTeachingSelected
                                                            ? this.state
                                                                  .teachingCourses[
                                                                  term.code
                                                              ]
                                                            : this.state
                                                                  .takingCourses[
                                                                  term.code
                                                              ]) ?? []
                                                    }
                                                    columns={(this.state
                                                        .isTeachingSelected
                                                        ? getTeachingColumns()
                                                        : getTakingColumns()
                                                    ).map(col => {
                                                        // TODO: Commenting the CCT part for portal beta version
                                                        if (col.isClickable) {
                                                            col = {
                                                                ...col,
                                                                onClick:
                                                                    course => {
                                                                        this.props.toggleCCT(
                                                                            course
                                                                        );
                                                                    }
                                                            };
                                                        }
                                                        // if (col.isClickable) {
                                                        //     col = {
                                                        //         ...col,
                                                        //         onClick: () =>
                                                        //             this.toggleCctIsModalOpen()
                                                        //     };
                                                        // }
                                                        return col;
                                                    })}
                                                    hideFooter
                                                    density="compact"
                                                    getRowId={row =>
                                                        this.state
                                                            .isTeachingSelected
                                                            ? row.CRNS +
                                                              '_' +
                                                              row.MEETING_DAYS +
                                                              '_' +
                                                              row.TIMES
                                                            : row.CRN
                                                    }
                                                    sx={{
                                                        m: 0,
                                                        overflow: 'hidden',
                                                        '& .MuiDataGrid-row--lastVisible .MuiDataGrid-cell':
                                                            {
                                                                borderBottomColor:
                                                                    'transparent'
                                                            }
                                                    }}
                                                    onClick={course =>
                                                        this.props.toggleCCT(
                                                            course
                                                        )
                                                    }
                                                    loading={
                                                        this.state.isLoading
                                                    }
                                                    slots={{
                                                        loadingOverlay:
                                                            LinearProgress
                                                    }}
                                                    columnVisibilityModel={
                                                        this.props.isSmall
                                                            ? columnsToHideForMobile
                                                            : {}
                                                    }
                                                />
                                            </Box>
                                        </TabPanel>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </Widget>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        terms: state.termsReducer.terms,
        termsUpdatedAt: state.termsReducer.updatedAt,
        user: state.AWSReducer.user,
        isCCT: state.CCTReducer.isCCT
    };
};

const mapDispatchToProps = dispatch => ({
    saveMyCoursesTaking: coursesTaking =>
        dispatch(saveMyCoursesTaking(coursesTaking)),
    saveMyCoursesTeaching: coursesTeaching =>
        dispatch(saveMyCoursesTeaching(coursesTeaching)),
    saveTerms: terms => dispatch(saveTerms(terms)),
    toggleCCT: course => dispatch(toggleCCT(course))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WithSmallScreen(MyCourses));
